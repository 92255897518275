import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'
import { Button } from '@trexity/common-client/lib/components'
import MerchantSignUpButton from '../../components/merchantSignUpButton'

export default function ProfileOverflow () {
  const [videoVisible, setVideoVisible] = React.useState(false)

  return (
    <Layout type='merchant'>
      <section className='well merchant-profile' style={{ maxWidth: 800, overflow: 'hidden' }}>
        <div>
          {!videoVisible ? (
            <div
              className="merchant-profile-yt responsive-video"
              style={{ backgroundImage: 'url("/img/merchant-profiles/youtube-overflow.jpg")' }}
              onClick={() => setVideoVisible(!videoVisible)}
            />
          ) : null}
          {videoVisible ? (
            <div className='responsive-video' style={{ borderRadius: 0 }}>
              <iframe
                src="https://www.youtube.com/embed/8M44JPiDXCY?rel=0&amp;modestbranding=1&amp;autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : null}
        </div>
        <div className="inner">
          <span className='mice'>Joined May 28, 2020</span>
          <h2 className='mb-0'>Overflow Brewing Company</h2>
          <p className='lede mb'>Q’s &amp; A’s with Brad &amp; Mitch from Overflow</p>
          <div>
            <h4 className='mt'>Tell us about Overflow.</h4>
            <p><strong>Brad:</strong> Overflow is a business that’s built around energy and love and passion for what you want to do in life. Our name stems from the word flow in general, and that’s a study of happiness and being content with the things you want to do in life. And that’s essentially what we’ve built here and what we’ve tried to model our day to day lives after.</p>
            <p className='mb'><strong>Mitch:</strong> Our people make us special, we have an amazing team. Our beer is an award-winning product and our music is some of the best in the city. We host live music events and corporate events and weddings and it’s a pretty cool spot.</p>

            <div className='mb-2'>
              <blockquote className='quote'>
                Trexity was there since day one. We were able to grow together. We were able to develop together, integrate together and the team at Trexity has just been fantastic.
              </blockquote>
            </div>

            <h4>What makes you guys different from other brew pubs?</h4>
            <p><strong>Mitch:</strong> I think what really makes us different is the size of the taproom for one, it’s the largest in Ontario. We’ve got seven award winning beers on tap. We host live music twice a week basically and we have a lot of fun with it.</p>
            <p className='mb-2'><strong>Brad:</strong> We feel we have some of the best food that can be paired with the style of beers that we put together. We’ll have everything from live music to comedy shows to parties and corporate events, and we’re a very big tapper, and we’re a large production brewery as well.</p>

            <h4>How has business evolved over the last while for Overflow?</h4>
            <p className='mb-2'><strong>Brad:</strong> Yeah, we’d love to say that we’re right on plan, but with the way the world has been over the last two years, it’s definitely been an interesting ride. We’ve found different ways to reach our customers, with our online systems and our online delivery, and making sure our customers are getting our beer without having to leave the comfort and safety of their homes.</p>

            <h4>How does Trexity help on that customer “reach” front?</h4>
            <p><strong>Mitch:</strong> Trexity was there since day one. We were able to grow together. We were able to develop together, integrate together and the team at Trexity has just been fantastic.</p>
            <p><strong>Brad:</strong> We are a Shopify house here. We’re very happy with our platform and Trexity seamlessly integrated with the platform that we have running. The team at Trexity is just so supportive with everything they pull together, making sure that our deliveries get there on time and safely. The drivers are fantastic. We’ve got nothing but great stuff to say about our friends at Trexity.</p>
            <p className='mb-2'>We’ve done almost 2000 deliveries with Trexity and this is just the beginning. We are expanding our home delivery service everyday and we’re ready to reach even more beer lovers in 2022! </p>

            <div className='row align-center space-between'>
              <div className='col mb d-mb-0'>
                <a href="https://overflowbeer.com/" target="_blank" rel="noreferrer">
                  <img src="/img/merchants/overflow.jpg" alt="Overflow Beer logo" style={{ maxWidth: 120 }} />
                </a>
              </div>

              <div className='col'>
                <a href='https://overflowbeer.com/' className='cta-link' target="_blank" rel="noreferrer">Visit Overflow <i className="material-icons">keyboard_arrow_right</i></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row justify-center'>
          <div className='col text-center'>
            <div className="inner">
              <h2>Keep the local community</h2>
              <p className='mb'>When people do what they love, they do it better.</p>
              <MerchantSignUpButton />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
